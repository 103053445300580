import { combine } from '@datadog/browser-core';
import { createDOMMutationObservable } from '../browser/domMutationObservable';
import { startPerformanceCollection } from '../browser/performanceCollection';
import { startRumAssembly } from '../domain/assembly';
import { startForegroundContexts } from '../domain/foregroundContexts';
import { startInternalContext } from '../domain/internalContext';
import { LifeCycle } from '../domain/lifeCycle';
import { startParentContexts } from '../domain/parentContexts';
import { startRequestCollection } from '../domain/requestCollection';
import { startActionCollection } from '../domain/rumEventsCollection/action/actionCollection';
import { startErrorCollection } from '../domain/rumEventsCollection/error/errorCollection';
import { startLongTaskCollection } from '../domain/rumEventsCollection/longTask/longTaskCollection';
import { startResourceCollection } from '../domain/rumEventsCollection/resource/resourceCollection';
import { startViewCollection } from '../domain/rumEventsCollection/view/viewCollection';
import { startRumSession } from '../domain/rumSession';
import { startRumBatch } from '../transport/batch';
export function startRum(initConfiguration, configuration, internalMonitoring, getCommonContext, initialViewName) {
    var lifeCycle = new LifeCycle();
    var session = startRumSession(configuration, lifeCycle);
    var domMutationObservable = createDOMMutationObservable();
    internalMonitoring.setExternalContextProvider(function () {
        return combine({
            application_id: initConfiguration.applicationId,
        }, parentContexts.findView(), getCommonContext().context);
    });
    var _a = startRumEventCollection(initConfiguration.applicationId, lifeCycle, configuration, session, getCommonContext), parentContexts = _a.parentContexts, foregroundContexts = _a.foregroundContexts;
    startLongTaskCollection(lifeCycle);
    startResourceCollection(lifeCycle, session);
    var _b = startViewCollection(lifeCycle, configuration, location, domMutationObservable, foregroundContexts, initialViewName), addTiming = _b.addTiming, startView = _b.startView;
    var addError = startErrorCollection(lifeCycle, configuration, foregroundContexts).addError;
    var addAction = startActionCollection(lifeCycle, domMutationObservable, configuration, foregroundContexts).addAction;
    startRequestCollection(lifeCycle, configuration);
    startPerformanceCollection(lifeCycle, configuration);
    var internalContext = startInternalContext(initConfiguration.applicationId, session, parentContexts);
    return {
        addAction: addAction,
        addError: addError,
        addTiming: addTiming,
        startView: startView,
        lifeCycle: lifeCycle,
        parentContexts: parentContexts,
        session: session,
        getInternalContext: internalContext.get,
    };
}
export function startRumEventCollection(applicationId, lifeCycle, configuration, session, getCommonContext) {
    var parentContexts = startParentContexts(lifeCycle, session);
    var foregroundContexts = startForegroundContexts(configuration);
    var batch = startRumBatch(configuration, lifeCycle);
    startRumAssembly(applicationId, configuration, lifeCycle, session, parentContexts, getCommonContext);
    return {
        parentContexts: parentContexts,
        foregroundContexts: foregroundContexts,
        stop: function () {
            // prevent batch from previous tests to keep running and send unwanted requests
            // could be replaced by stopping all the component when they will all have a stop method
            batch.stop();
            parentContexts.stop();
            foregroundContexts.stop();
        },
    };
}
