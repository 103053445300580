import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@wilson/wilsonng';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import { Wilson } from 'src/def/Wilson';
import InitialLoad = Wilson.InitialLoad;

@Injectable({
  providedIn: 'root',
})
export class InitialLoadService {
  initialLoad: InitialLoad;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private logService: LogService,
    private httpClient: HttpClient,
    private handler: HttpBackend
  ) {
    // Workaround to bypass the AuthHttpInterceptor. This should only be used for the getInitialLoad call.
    // Any other HTTP calls should use the ApiService
    this.httpClient = new HttpClient(this.handler);
  }

  getInitialLoad(): Promise<InitialLoad> {
    return this.httpClient
      .get('initial-load')
      .pipe(
        tap((load: InitialLoad) => {
          this.initialLoad = load;
          if (load) {
            this.initializeGoogleAnalytics();
            this.logService.setupDatadogMonitoring(
              this.initialLoad.datadogTokens['MONITORING_ENVIRONMENT'],
              this.initialLoad.datadogTokens['SAMPLE_RATE'],
              this.initialLoad.datadogTokens['APPLICATION_ID'],
              this.initialLoad.datadogTokens['CLIENT_TOKEN']
            );
          }
        })
      )
      .toPromise();
  }

  private initializeGoogleAnalytics(): void {
    this.googleAnalyticsService.setKey(this.initialLoad.googleAnalyticsKey);
  }
}
