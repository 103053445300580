import { Component, OnInit } from '@angular/core';
import { AppInitService } from './services/app-init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadingMessage: string;

  constructor(private appInitService: AppInitService) {}

  ngOnInit(): void {
    this.appInitService.init();
  }
}
