import { monitor, Observable, timeStampNow } from '@datadog/browser-core';
import { LifeCycleEventType } from './lifeCycle';
// Delay to wait for a page activity to validate the tracking process
export var PAGE_ACTIVITY_VALIDATION_DELAY = 100;
// Delay to wait after a page activity to end the tracking process
export var PAGE_ACTIVITY_END_DELAY = 100;
// Maximum duration of the tracking process
export var PAGE_ACTIVITY_MAX_DURATION = 10000;
export function waitIdlePageActivity(lifeCycle, domMutationObservable, completionCallback) {
    var _a = trackPageActivities(lifeCycle, domMutationObservable), pageActivitiesObservable = _a.observable, stopPageActivitiesTracking = _a.stop;
    var stopWaitPageActivitiesCompletion = waitPageActivitiesCompletion(pageActivitiesObservable, stopPageActivitiesTracking, completionCallback).stop;
    var stop = function () {
        stopWaitPageActivitiesCompletion();
        stopPageActivitiesTracking();
    };
    return { stop: stop };
}
// Automatic action collection lifecycle overview:
//                      (Start new trackPageActivities)
//              .-------------------'--------------------.
//              v                                        v
//     [Wait for a page activity ]          [Wait for a maximum duration]
//     [timeout: VALIDATION_DELAY]          [  timeout: MAX_DURATION    ]
//          /                  \                           |
//         v                    v                          |
//  [No page activity]   [Page activity]                   |
//         |                   |,----------------------.   |
//         v                   v                       |   |
//     (Discard)     [Wait for a page activity]        |   |
//                   [   timeout: END_DELAY   ]        |   |
//                       /                \            |   |
//                      v                  v           |   |
//             [No page activity]    [Page activity]   |   |
//                      |                 |            |   |
//                      |                 '------------'   |
//                      '-----------. ,--------------------'
//                                   v
//                                 (End)
//
// Note: because MAX_DURATION > VALIDATION_DELAY, we are sure that if the process is still alive
// after MAX_DURATION, it has been validated.
export function trackPageActivities(lifeCycle, domMutationObservable) {
    var observable = new Observable();
    var subscriptions = [];
    var firstRequestIndex;
    var pendingRequestsCount = 0;
    subscriptions.push(domMutationObservable.subscribe(function () { return notifyPageActivity(); }));
    subscriptions.push(lifeCycle.subscribe(LifeCycleEventType.PERFORMANCE_ENTRY_COLLECTED, function (entry) {
        if (entry.entryType !== 'resource') {
            return;
        }
        notifyPageActivity();
    }));
    subscriptions.push(lifeCycle.subscribe(LifeCycleEventType.REQUEST_STARTED, function (startEvent) {
        if (firstRequestIndex === undefined) {
            firstRequestIndex = startEvent.requestIndex;
        }
        pendingRequestsCount += 1;
        notifyPageActivity();
    }));
    subscriptions.push(lifeCycle.subscribe(LifeCycleEventType.REQUEST_COMPLETED, function (request) {
        // If the request started before the tracking start, ignore it
        if (firstRequestIndex === undefined || request.requestIndex < firstRequestIndex) {
            return;
        }
        pendingRequestsCount -= 1;
        notifyPageActivity();
    }));
    function notifyPageActivity() {
        observable.notify({ isBusy: pendingRequestsCount > 0 });
    }
    return {
        observable: observable,
        stop: function () {
            subscriptions.forEach(function (s) { return s.unsubscribe(); });
        },
    };
}
export function waitPageActivitiesCompletion(pageActivitiesObservable, stopPageActivitiesTracking, completionCallback) {
    var idleTimeoutId;
    var hasCompleted = false;
    var validationTimeoutId = setTimeout(monitor(function () { return complete({ hadActivity: false }); }), PAGE_ACTIVITY_VALIDATION_DELAY);
    var maxDurationTimeoutId = setTimeout(monitor(function () { return complete({ hadActivity: true, endTime: timeStampNow() }); }), PAGE_ACTIVITY_MAX_DURATION);
    pageActivitiesObservable.subscribe(function (_a) {
        var isBusy = _a.isBusy;
        clearTimeout(validationTimeoutId);
        clearTimeout(idleTimeoutId);
        var lastChangeTime = timeStampNow();
        if (!isBusy) {
            idleTimeoutId = setTimeout(monitor(function () { return complete({ hadActivity: true, endTime: lastChangeTime }); }), PAGE_ACTIVITY_END_DELAY);
        }
    });
    var stop = function () {
        hasCompleted = true;
        clearTimeout(validationTimeoutId);
        clearTimeout(idleTimeoutId);
        clearTimeout(maxDurationTimeoutId);
        stopPageActivitiesTracking();
    };
    function complete(params) {
        if (hasCompleted) {
            return;
        }
        stop();
        completionCallback(params);
    }
    return { stop: stop };
}
