import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  RedirectService,
  RouterNavigationService,
} from '@wilson/wilsonng';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { Wilson } from 'src/def/Wilson';
import User = Wilson.User;

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private userService: UserService,
    private routerNavigationService: RouterNavigationService,
    private router: Router,
    private redirectService: RedirectService,
    private authenticationService: AuthenticationService
  ) {}

  init(): void {
    this.authenticationService
      .getUser()
      .pipe(
        switchMap((user: User) => {
          // If user is not authed, send them to login first
          if (!user) {
            const path = this.getWindow().location.pathname;
            if (path !== '/') {
              this.redirectService.setRedirectCookie();
              this.authenticationService.login();
            } else {
              // auto-redirect to admin portal by default
              this.authenticationService.login('/admin');
            }
            return of(null);
          } else {
            this.userService.registerUserWithGoogleAnalytics(user);
            this.initRouting();
            return this.userService.loginUser();
          }
        })
      )
      .subscribe();
  }

  // Useful for mocking "window" in tests
  getWindow(): Window {
    return window;
  }

  private initRouting(): void {
    this.routerNavigationService.startTrackingPreviousUrl();
    this.router.initialNavigation();
  }
}
