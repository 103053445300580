export var LifeCycleEventType;
(function (LifeCycleEventType) {
    LifeCycleEventType[LifeCycleEventType["PERFORMANCE_ENTRY_COLLECTED"] = 0] = "PERFORMANCE_ENTRY_COLLECTED";
    LifeCycleEventType[LifeCycleEventType["AUTO_ACTION_CREATED"] = 1] = "AUTO_ACTION_CREATED";
    LifeCycleEventType[LifeCycleEventType["AUTO_ACTION_COMPLETED"] = 2] = "AUTO_ACTION_COMPLETED";
    LifeCycleEventType[LifeCycleEventType["AUTO_ACTION_DISCARDED"] = 3] = "AUTO_ACTION_DISCARDED";
    LifeCycleEventType[LifeCycleEventType["VIEW_CREATED"] = 4] = "VIEW_CREATED";
    LifeCycleEventType[LifeCycleEventType["VIEW_UPDATED"] = 5] = "VIEW_UPDATED";
    LifeCycleEventType[LifeCycleEventType["VIEW_ENDED"] = 6] = "VIEW_ENDED";
    LifeCycleEventType[LifeCycleEventType["REQUEST_STARTED"] = 7] = "REQUEST_STARTED";
    LifeCycleEventType[LifeCycleEventType["REQUEST_COMPLETED"] = 8] = "REQUEST_COMPLETED";
    LifeCycleEventType[LifeCycleEventType["SESSION_RENEWED"] = 9] = "SESSION_RENEWED";
    LifeCycleEventType[LifeCycleEventType["BEFORE_UNLOAD"] = 10] = "BEFORE_UNLOAD";
    LifeCycleEventType[LifeCycleEventType["RAW_RUM_EVENT_COLLECTED"] = 11] = "RAW_RUM_EVENT_COLLECTED";
    LifeCycleEventType[LifeCycleEventType["RUM_EVENT_COLLECTED"] = 12] = "RUM_EVENT_COLLECTED";
    LifeCycleEventType[LifeCycleEventType["RECORD_STARTED"] = 13] = "RECORD_STARTED";
    LifeCycleEventType[LifeCycleEventType["RECORD_STOPPED"] = 14] = "RECORD_STOPPED";
    LifeCycleEventType[LifeCycleEventType["RAW_ERROR_COLLECTED"] = 15] = "RAW_ERROR_COLLECTED";
})(LifeCycleEventType || (LifeCycleEventType = {}));
var LifeCycle = /** @class */ (function () {
    function LifeCycle() {
        this.callbacks = {};
    }
    LifeCycle.prototype.notify = function (eventType, data) {
        var eventCallbacks = this.callbacks[eventType];
        if (eventCallbacks) {
            eventCallbacks.forEach(function (callback) { return callback(data); });
        }
    };
    LifeCycle.prototype.subscribe = function (eventType, callback) {
        var _this = this;
        if (!this.callbacks[eventType]) {
            this.callbacks[eventType] = [];
        }
        this.callbacks[eventType].push(callback);
        return {
            unsubscribe: function () {
                _this.callbacks[eventType] = _this.callbacks[eventType].filter(function (other) { return callback !== other; });
            },
        };
    };
    return LifeCycle;
}());
export { LifeCycle };
