import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./funnels/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    // p = 'public'
    path: 'p',
    loadChildren: () =>
      import('./funnels/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./funnels/client/client.module').then((m) => m.ClientModule),
  },
  { path: '**', redirectTo: 'p/notfound', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
