import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ToastModule } from 'primeng/toast';
import { ApiService, SpinnerModule, TitleService } from '@wilson/wilsonng';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InitialLoadService } from './services/initial-load.service';
import { UIModule } from './ui.module';
import { RouterModule, TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from './services/page-title.service';

@NgModule({
  declarations: [
    // Components
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    SpinnerModule,
    ToastModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
      paramsInheritanceStrategy: 'always',
    }),
    AppRoutingModule,

    UIModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    DatePipe,
    ApiService,
    TitleService,
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: 'productName',
      useValue: 'Wordalyzer',
    },
    {
      provide: 'baseApiUrl',
      useValue: document.getElementsByTagName('base')[0].href,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (ils: InitialLoadService) => () => ils.getInitialLoad(),
      deps: [InitialLoadService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
