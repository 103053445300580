import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  public setupDatadogMonitoring(
    datadogEnvironment: string,
    datadogSampleRate: string,
    datadogApplicationId: string,
    datadogClientToken: string
  ): void {
    if (datadogEnvironment && datadogEnvironment.length) {
      datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        site: 'datadoghq.com',
        service: 'document-viewer',
        env: datadogEnvironment,
        sampleRate: +datadogSampleRate || 100,
        trackInteractions: true,
      });
    }
  }
}
