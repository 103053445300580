var _a;
import { __assign } from "tslib";
import { BuildMode } from '../boot/init';
import { includes } from '../tools/utils';
var ENDPOINTS = {
    alternate: {
        logs: 'logs',
        rum: 'rum',
        sessionReplay: 'session-replay',
        trace: 'trace',
    },
    classic: {
        logs: 'browser',
        rum: 'rum',
        // session-replay has no classic endpoint
        sessionReplay: undefined,
        trace: 'public-trace',
    },
};
export var Datacenter = {
    EU: 'eu',
    US: 'us',
};
export var INTAKE_SITE = (_a = {},
    _a[Datacenter.EU] = 'datadoghq.eu',
    _a[Datacenter.US] = 'datadoghq.com',
    _a);
var CLASSIC_ALLOWED_SITES = [INTAKE_SITE[Datacenter.US], INTAKE_SITE[Datacenter.EU]];
export function computeTransportConfiguration(initConfiguration, buildEnv) {
    var transportSettings = {
        buildMode: buildEnv.buildMode,
        clientToken: initConfiguration.clientToken,
        env: initConfiguration.env,
        proxyHost: initConfiguration.proxyHost,
        sdkVersion: buildEnv.sdkVersion,
        service: initConfiguration.service,
        site: initConfiguration.site || INTAKE_SITE[initConfiguration.datacenter || buildEnv.datacenter],
        version: initConfiguration.version,
    };
    var intakeType = getIntakeType(transportSettings.site, initConfiguration);
    var intakeUrls = getIntakeUrls(intakeType, transportSettings, initConfiguration.replica !== undefined);
    var configuration = {
        isIntakeUrl: function (url) { return intakeUrls.some(function (intakeUrl) { return url.indexOf(intakeUrl) === 0; }); },
        logsEndpoint: getEndpoint(intakeType, 'logs', transportSettings),
        rumEndpoint: getEndpoint(intakeType, 'rum', transportSettings),
        sessionReplayEndpoint: getEndpoint(intakeType, 'sessionReplay', transportSettings),
        traceEndpoint: getEndpoint(intakeType, 'trace', transportSettings),
    };
    if (initConfiguration.internalMonitoringApiKey) {
        configuration.internalMonitoringEndpoint = getEndpoint(intakeType, 'logs', transportSettings, 'browser-agent-internal-monitoring');
    }
    if (transportSettings.buildMode === BuildMode.E2E_TEST) {
        configuration.internalMonitoringEndpoint = '<<< E2E INTERNAL MONITORING ENDPOINT >>>';
        configuration.logsEndpoint = '<<< E2E LOGS ENDPOINT >>>';
        configuration.rumEndpoint = '<<< E2E RUM ENDPOINT >>>';
        configuration.sessionReplayEndpoint = '<<< E2E SESSION REPLAY ENDPOINT >>>';
    }
    if (transportSettings.buildMode === BuildMode.STAGING) {
        if (initConfiguration.replica !== undefined) {
            var replicaTransportSettings = __assign(__assign({}, transportSettings), { applicationId: initConfiguration.replica.applicationId, clientToken: initConfiguration.replica.clientToken, site: INTAKE_SITE[Datacenter.US] });
            configuration.replica = {
                applicationId: initConfiguration.replica.applicationId,
                internalMonitoringEndpoint: getEndpoint(intakeType, 'logs', replicaTransportSettings, 'browser-agent-internal-monitoring'),
                logsEndpoint: getEndpoint(intakeType, 'logs', replicaTransportSettings),
                rumEndpoint: getEndpoint(intakeType, 'rum', replicaTransportSettings),
            };
        }
    }
    return configuration;
}
function getIntakeType(site, initConfiguration) {
    return !initConfiguration.useAlternateIntakeDomains && includes(CLASSIC_ALLOWED_SITES, site) ? 'classic' : 'alternate';
}
function getIntakeUrls(intakeType, settings, withReplica) {
    if (settings.proxyHost) {
        return ["https://" + settings.proxyHost + "/v1/input/"];
    }
    var sites = [settings.site];
    if (settings.buildMode === BuildMode.STAGING && withReplica) {
        sites.push(INTAKE_SITE[Datacenter.US]);
    }
    var urls = [];
    var endpointTypes = Object.keys(ENDPOINTS[intakeType]);
    for (var _i = 0, sites_1 = sites; _i < sites_1.length; _i++) {
        var site = sites_1[_i];
        for (var _a = 0, endpointTypes_1 = endpointTypes; _a < endpointTypes_1.length; _a++) {
            var endpointType = endpointTypes_1[_a];
            urls.push("https://" + getHost(intakeType, endpointType, site) + "/v1/input/");
        }
    }
    return urls;
}
function getHost(intakeType, endpointType, site) {
    return (intakeType === 'classic' && getClassicHost(endpointType, site)) || getAlternateHost(endpointType, site);
}
function getClassicHost(endpointType, site) {
    var endpoint = ENDPOINTS.classic[endpointType];
    return endpoint && endpoint + "-http-intake.logs." + site;
}
function getAlternateHost(endpointType, site) {
    var endpoint = ENDPOINTS.alternate[endpointType];
    var domainParts = site.split('.');
    var extension = domainParts.pop();
    var suffix = domainParts.join('-') + "." + extension;
    return endpoint + ".browser-intake-" + suffix;
}
function getEndpoint(intakeType, endpointType, settings, source) {
    var tags = "sdk_version:" + settings.sdkVersion +
        ("" + (settings.env ? ",env:" + settings.env : '')) +
        ("" + (settings.service ? ",service:" + settings.service : '')) +
        ("" + (settings.version ? ",version:" + settings.version : ''));
    var datadogHost = getHost(intakeType, endpointType, settings.site);
    var host = settings.proxyHost ? settings.proxyHost : datadogHost;
    var proxyParameter = settings.proxyHost ? "ddhost=" + datadogHost + "&" : '';
    var parameters = proxyParameter + "ddsource=" + (source || 'browser') + "&ddtags=" + encodeURIComponent(tags);
    return "https://" + host + "/v1/input/" + settings.clientToken + "?" + parameters;
}
