import { __spreadArrays } from "tslib";
import { combine, createErrorFilter, isEmptyObject, limitModification, timeStampNow, currentDrift, display, addMonitoringMessage, relativeNow, } from '@datadog/browser-core';
import { RumEventType, } from '../rawRumEvent.types';
import { LifeCycleEventType } from './lifeCycle';
var SessionType;
(function (SessionType) {
    SessionType["SYNTHETICS"] = "synthetics";
    SessionType["USER"] = "user";
})(SessionType || (SessionType = {}));
var VIEW_EVENTS_MODIFIABLE_FIELD_PATHS = [
    // Fields with sensitive data
    'view.url',
    'view.referrer',
    'action.target.name',
    'error.message',
    'error.stack',
    'error.resource.url',
    'resource.url',
];
var OTHER_EVENTS_MODIFIABLE_FIELD_PATHS = __spreadArrays(VIEW_EVENTS_MODIFIABLE_FIELD_PATHS, [
    // User-customizable field
    'context',
]);
export function startRumAssembly(applicationId, configuration, lifeCycle, session, parentContexts, getCommonContext) {
    var errorFilter = createErrorFilter(configuration, function (error) {
        lifeCycle.notify(LifeCycleEventType.RAW_ERROR_COLLECTED, { error: error });
    });
    lifeCycle.subscribe(LifeCycleEventType.RAW_RUM_EVENT_COLLECTED, function (_a) {
        var startTime = _a.startTime, rawRumEvent = _a.rawRumEvent, domainContext = _a.domainContext, savedCommonContext = _a.savedCommonContext, customerContext = _a.customerContext;
        var viewContext = parentContexts.findView(startTime);
        var plan = session.getPlan();
        if (plan && viewContext && viewContext.session.id === session.getId()) {
            var actionContext = parentContexts.findAction(startTime);
            var commonContext = savedCommonContext || getCommonContext();
            var rumContext = {
                _dd: {
                    format_version: 2,
                    drift: currentDrift(),
                    session: {
                        plan: plan,
                    },
                },
                application: {
                    id: applicationId,
                },
                date: timeStampNow(),
                service: configuration.service,
                session: {
                    // must be computed on each event because synthetics instrumentation can be done after sdk execution
                    // cf https://github.com/puppeteer/puppeteer/issues/3667
                    type: getSessionType(),
                },
            };
            var serverRumEvent = (needToAssembleWithAction(rawRumEvent)
                ? combine(rumContext, viewContext, actionContext, rawRumEvent)
                : combine(rumContext, viewContext, rawRumEvent));
            serverRumEvent.context = combine(commonContext.context, customerContext);
            if (!('has_replay' in serverRumEvent.session)) {
                ;
                serverRumEvent.session.has_replay = commonContext.hasReplay;
            }
            if (!isEmptyObject(commonContext.user)) {
                ;
                serverRumEvent.usr = commonContext.user;
            }
            if (shouldSend(serverRumEvent, configuration.beforeSend, domainContext, errorFilter)) {
                if (isEmptyObject(serverRumEvent.context)) {
                    delete serverRumEvent.context;
                }
                if (typeof serverRumEvent.date !== 'number') {
                    addMonitoringMessage('invalid date', {
                        debug: {
                            eventType: serverRumEvent.type,
                            eventTimeStamp: serverRumEvent.date,
                            eventRelativeTime: Math.round(startTime),
                            timeStampNow: timeStampNow(),
                            relativeNow: Math.round(relativeNow()),
                            drift: currentDrift(),
                        },
                    });
                }
                lifeCycle.notify(LifeCycleEventType.RUM_EVENT_COLLECTED, serverRumEvent);
            }
        }
    });
}
function shouldSend(event, beforeSend, domainContext, errorFilter) {
    if (beforeSend) {
        var result = limitModification(event, event.type === RumEventType.VIEW ? VIEW_EVENTS_MODIFIABLE_FIELD_PATHS : OTHER_EVENTS_MODIFIABLE_FIELD_PATHS, function (event) { return beforeSend(event, domainContext); });
        if (result === false && event.type !== RumEventType.VIEW) {
            return false;
        }
        if (result === false) {
            display.warn("Can't dismiss view events using beforeSend!");
        }
    }
    if (event.type === RumEventType.ERROR) {
        return !errorFilter.isLimitReached();
    }
    return true;
}
function needToAssembleWithAction(event) {
    return [RumEventType.ERROR, RumEventType.RESOURCE, RumEventType.LONG_TASK].indexOf(event.type) !== -1;
}
function getSessionType() {
    return window._DATADOG_SYNTHETICS_BROWSER === undefined ? SessionType.USER : SessionType.SYNTHETICS;
}
