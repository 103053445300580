import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TitleService } from '@wilson/wilsonng';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(private titleService: TitleService) {
    super();
  }

  override updateTitle = (routerState: RouterStateSnapshot) =>
    this.titleService.setPageName(this.buildTitle(routerState));
}
