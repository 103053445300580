import { RequestType, startFetchProxy, startXhrProxy, } from '@datadog/browser-core';
import { LifeCycleEventType } from './lifeCycle';
import { isAllowedRequestUrl } from './rumEventsCollection/resource/resourceUtils';
import { startTracer } from './tracing/tracer';
var nextRequestIndex = 1;
export function startRequestCollection(lifeCycle, configuration) {
    var tracer = startTracer(configuration);
    trackXhr(lifeCycle, configuration, tracer);
    trackFetch(lifeCycle, configuration, tracer);
}
export function trackXhr(lifeCycle, configuration, tracer) {
    var xhrProxy = startXhrProxy();
    xhrProxy.beforeSend(function (context, xhr) {
        if (isAllowedRequestUrl(configuration, context.url)) {
            tracer.traceXhr(context, xhr);
            context.requestIndex = getNextRequestIndex();
            lifeCycle.notify(LifeCycleEventType.REQUEST_STARTED, {
                requestIndex: context.requestIndex,
            });
        }
    });
    xhrProxy.onRequestComplete(function (context) {
        if (isAllowedRequestUrl(configuration, context.url)) {
            tracer.clearTracingIfNeeded(context);
            lifeCycle.notify(LifeCycleEventType.REQUEST_COMPLETED, {
                duration: context.duration,
                method: context.method,
                requestIndex: context.requestIndex,
                responseText: context.responseText,
                spanId: context.spanId,
                startClocks: context.startClocks,
                status: context.status,
                traceId: context.traceId,
                type: RequestType.XHR,
                url: context.url,
                xhr: context.xhr,
            });
        }
    });
    return xhrProxy;
}
export function trackFetch(lifeCycle, configuration, tracer) {
    var fetchProxy = startFetchProxy();
    fetchProxy.beforeSend(function (context) {
        if (isAllowedRequestUrl(configuration, context.url)) {
            tracer.traceFetch(context);
            context.requestIndex = getNextRequestIndex();
            lifeCycle.notify(LifeCycleEventType.REQUEST_STARTED, {
                requestIndex: context.requestIndex,
            });
        }
    });
    fetchProxy.onRequestComplete(function (context) {
        if (isAllowedRequestUrl(configuration, context.url)) {
            tracer.clearTracingIfNeeded(context);
            lifeCycle.notify(LifeCycleEventType.REQUEST_COMPLETED, {
                duration: context.duration,
                method: context.method,
                requestIndex: context.requestIndex,
                responseText: context.responseText,
                responseType: context.responseType,
                spanId: context.spanId,
                startClocks: context.startClocks,
                status: context.status,
                traceId: context.traceId,
                type: RequestType.FETCH,
                url: context.url,
                response: context.response,
                init: context.init,
                input: context.input,
            });
        }
    });
    return fetchProxy;
}
function getNextRequestIndex() {
    var result = nextRequestIndex;
    nextRequestIndex += 1;
    return result;
}
