import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  pHttpErrorInterceptorOn = new BehaviorSubject<boolean>(true);
  public httpErrorInterceptorOn = this.pHttpErrorInterceptorOn.asObservable();

  activateInterceptor() {
    this.pHttpErrorInterceptorOn.next(true);
  }

  deactivateInterceptor() {
    this.pHttpErrorInterceptorOn.next(false);
  }
}
