import { ErrorSource, ErrorHandling, formatUnknownError } from '../../tools/error';
import { clocksNow } from '../../tools/timeUtils';
import { subscribe, unsubscribe } from '../tracekit';
var traceKitReportHandler;
export function trackRuntimeError(errorObservable) {
    traceKitReportHandler = function (stackTrace, _, errorObject) {
        var _a = formatUnknownError(stackTrace, errorObject, 'Uncaught'), stack = _a.stack, message = _a.message, type = _a.type;
        errorObservable.notify({
            message: message,
            stack: stack,
            type: type,
            source: ErrorSource.SOURCE,
            startClocks: clocksNow(),
            originalError: errorObject,
            handling: ErrorHandling.UNHANDLED,
        });
    };
    subscribe(traceKitReportHandler);
    return {
        stop: function () {
            unsubscribe(traceKitReportHandler);
        },
    };
}
