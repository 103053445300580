import { performDraw, startSessionManagement } from '@datadog/browser-core';
import { LifeCycleEventType } from './lifeCycle';
export var RUM_SESSION_KEY = 'rum';
export var RumSessionPlan;
(function (RumSessionPlan) {
    RumSessionPlan[RumSessionPlan["LITE"] = 1] = "LITE";
    RumSessionPlan[RumSessionPlan["REPLAY"] = 2] = "REPLAY";
})(RumSessionPlan || (RumSessionPlan = {}));
export var RumTrackingType;
(function (RumTrackingType) {
    RumTrackingType["NOT_TRACKED"] = "0";
    RumTrackingType["TRACKED_WITH_RESOURCES"] = "1";
    RumTrackingType["TRACKED_WITHOUT_RESOURCES"] = "2";
})(RumTrackingType || (RumTrackingType = {}));
export function startRumSession(configuration, lifeCycle) {
    var session = startSessionManagement(configuration.cookieOptions, RUM_SESSION_KEY, function (rawTrackingType) {
        return computeSessionState(configuration, rawTrackingType);
    });
    session.renewObservable.subscribe(function () {
        lifeCycle.notify(LifeCycleEventType.SESSION_RENEWED);
    });
    return {
        getId: session.getId,
        getPlan: function () { return getSessionPlan(session); },
        isTracked: function () { return isSessionTracked(session); },
        isTrackedWithResource: function () {
            return session.getId() !== undefined && session.getTrackingType() === RumTrackingType.TRACKED_WITH_RESOURCES;
        },
    };
}
function isSessionTracked(session) {
    return session.getId() !== undefined && isTypeTracked(session.getTrackingType());
}
function getSessionPlan(session) {
    return isSessionTracked(session)
        ? // TODO: return correct plan based on tracking type
            RumSessionPlan.REPLAY
        : undefined;
}
function computeSessionState(configuration, rawTrackingType) {
    var trackingType;
    if (hasValidRumSession(rawTrackingType)) {
        trackingType = rawTrackingType;
    }
    else if (!performDraw(configuration.sampleRate)) {
        trackingType = RumTrackingType.NOT_TRACKED;
    }
    else if (!performDraw(configuration.resourceSampleRate)) {
        trackingType = RumTrackingType.TRACKED_WITHOUT_RESOURCES;
    }
    else {
        trackingType = RumTrackingType.TRACKED_WITH_RESOURCES;
    }
    return {
        trackingType: trackingType,
        isTracked: isTypeTracked(trackingType),
    };
}
function hasValidRumSession(trackingType) {
    return (trackingType === RumTrackingType.NOT_TRACKED ||
        trackingType === RumTrackingType.TRACKED_WITH_RESOURCES ||
        trackingType === RumTrackingType.TRACKED_WITHOUT_RESOURCES);
}
function isTypeTracked(rumSessionType) {
    return (rumSessionType === RumTrackingType.TRACKED_WITH_RESOURCES ||
        rumSessionType === RumTrackingType.TRACKED_WITHOUT_RESOURCES);
}
