<div id="app">
  <router-outlet></router-outlet>

  <!-- custom toast notifications -->
  <p-toast position="bottom-center">
    <ng-template let-message pTemplate="message">
      <div class="w-100">
        <h5 *ngIf="message.detail">{{ message.summary }}</h5>
        <p class="m-0" [innerHTML]="message.detail || message.summary"></p>
      </div>
    </ng-template>
  </p-toast>
</div>

<wilson-spinner></wilson-spinner>

<div class="loadingMessage" *ngIf="loadingMessage">
  {{ loadingMessage }}
</div>
