import { monitor, addEventListener } from '@datadog/browser-core';
export function trackLocationChanges(onLocationChange) {
    var stopHistoryTracking = trackHistory(onLocationChange).stop;
    var stopHashTracking = trackHash(onLocationChange).stop;
    return {
        stop: function () {
            stopHistoryTracking();
            stopHashTracking();
        },
    };
}
export function areDifferentLocation(currentLocation, otherLocation) {
    return (currentLocation.pathname !== otherLocation.pathname ||
        (!isHashAnAnchor(otherLocation.hash) &&
            getPathFromHash(otherLocation.hash) !== getPathFromHash(currentLocation.hash)));
}
function trackHistory(onHistoryChange) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    var originalPushState = history.pushState;
    history.pushState = monitor(function () {
        originalPushState.apply(this, arguments);
        onHistoryChange();
    });
    // eslint-disable-next-line @typescript-eslint/unbound-method
    var originalReplaceState = history.replaceState;
    history.replaceState = monitor(function () {
        originalReplaceState.apply(this, arguments);
        onHistoryChange();
    });
    var removeListener = addEventListener(window, "popstate" /* POP_STATE */, onHistoryChange).stop;
    var stop = function () {
        removeListener();
        history.pushState = originalPushState;
        history.replaceState = originalReplaceState;
    };
    return { stop: stop };
}
function trackHash(onHashChange) {
    return addEventListener(window, "hashchange" /* HASH_CHANGE */, onHashChange);
}
function isHashAnAnchor(hash) {
    var correspondingId = hash.substr(1);
    return !!document.getElementById(correspondingId);
}
function getPathFromHash(hash) {
    var index = hash.indexOf('?');
    return index < 0 ? hash : hash.slice(0, index);
}
