import { resetFetchProxy, startFetchProxy } from '../../browser/fetchProxy';
import { resetXhrProxy, startXhrProxy } from '../../browser/xhrProxy';
import { ErrorSource } from '../../tools/error';
import { RequestType } from '../../tools/utils';
export function trackNetworkError(configuration, errorObservable, trackAbortedRequests) {
    if (trackAbortedRequests === void 0) { trackAbortedRequests = true; }
    startXhrProxy().onRequestComplete(function (context) { return handleCompleteRequest(RequestType.XHR, context); });
    startFetchProxy().onRequestComplete(function (context) { return handleCompleteRequest(RequestType.FETCH, context); });
    function handleCompleteRequest(type, request) {
        if (!configuration.isIntakeUrl(request.url) &&
            (trackAbortedRequests || !request.isAborted) &&
            (isRejected(request) || isServerError(request))) {
            errorObservable.notify({
                message: format(type) + " error " + request.method + " " + request.url,
                resource: {
                    method: request.method,
                    statusCode: request.status,
                    url: request.url,
                },
                source: ErrorSource.NETWORK,
                stack: truncateResponseText(request.responseText, configuration) || 'Failed to load',
                startClocks: request.startClocks,
            });
        }
    }
    return {
        stop: function () {
            resetXhrProxy();
            resetFetchProxy();
        },
    };
}
function isRejected(request) {
    return request.status === 0 && request.responseType !== 'opaque';
}
function isServerError(request) {
    return request.status >= 500;
}
function truncateResponseText(responseText, configuration) {
    if (responseText && responseText.length > configuration.requestErrorResponseLengthLimit) {
        return responseText.substring(0, configuration.requestErrorResponseLengthLimit) + "...";
    }
    return responseText;
}
function format(type) {
    if (RequestType.XHR === type) {
        return 'XHR';
    }
    return 'Fetch';
}
